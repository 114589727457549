import React, { useState, useEffect } from "react";
import Plot from 'react-plotly.js';
import { Base64 } from 'js-base64';
import { useParams } from 'react-router-dom';
import { API_BASE_URL } from "../../App";
import '../../css/ChartSection.css';

function StrategyChart() {
    const { symbol, strategy } = useParams();  // Extract symbol and strategy from route params
    const [chartData, setChartData] = useState(null);
    const [loading, setLoading] = useState(true);

    // Fetch chart data using the symbol and strategy
    useEffect(() => {
        const fetchData = async () => {
            if (!symbol || !strategy) return;  // Ensure symbol and strategy are provided

            setLoading(true);
            try {
                const filters = {
                    symbol: symbol,
                    strategy: strategy
                };

                // Convert filters to JSON and url-safe encode in base64
                const encodedFilters = Base64.encodeURL(JSON.stringify(filters));

                // Construct the API URL with encoded filters
                const apiUrl = `${API_BASE_URL}/trade-strategies?filters=${encodedFilters}`;
                console.log("API URL:", apiUrl);

                // Make the API request using fetch
                const response = await fetch(apiUrl);
                const data = await response.json();

                // Check for chart data in the response and parse it
                if (data[0] && data[0].result && data[0].result.chart) {
                    const investmentSummaryChart = JSON.parse(data[0].result.chart); // Parse the stringified JSON chart data
                    setChartData(investmentSummaryChart); // Set the parsed data to state
                } else {
                    console.error("No chart data available in the response");
                }
            } catch (error) {
                console.error("Error fetching chart data:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [symbol, strategy]);

    if (loading) return <div>Loading...</div>;

    if (!chartData) return <div>No chart data available</div>;

    return (
        <div className="chart-wrapper">
            <Plot
                data={chartData.data}  // 'data' for the Plotly chart
                layout={chartData.layout}  // 'layout' for the Plotly chart
                config={{ responsive: true }}  // Ensuring chart is responsive
                style={{ width: '100%', height: '100%' }}
                useResizeHandler={true}
            />
        </div>
    );
}

export default StrategyChart;