import React, { useState, useEffect, useCallback } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Button from '@atlaskit/button/new';
import TextField from '@atlaskit/textfield';
import SearchIcon from '@atlaskit/icon/glyph/search';
import HomeIcon from '@atlaskit/icon/glyph/home';
import { Base64 } from 'js-base64';
import { API_BASE_URL } from "./App";
import TradePopup from './widgets/TradePopup';

function MyToolbar() {
  const location = useLocation();
  const navigate = useNavigate();

  const contactUs = "https://docs.google.com/forms/d/e/1FAIpQLSdBbmzpHmaZIJS3J1HMZAPFKNRIu9FGM1CQAKos1eSBj1aiyA/viewform?usp=sf_link"

  const currentSymbol = location.pathname.match(/\/(fundamentals|company-info|analysis)\/([^/]+)/)?.[2] || null;
  const isTerminalPage = location.pathname.startsWith('/terminal');

  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isTradePopupOpen, setIsTradePopupOpen] = useState(false);

  const handleSearchSelect = (symbol) => {
    setSearchQuery('');
    setSearchResults([]);
    navigate(`/fundamentals/${symbol}`);
  };

  const fetchSearchResults = useCallback(async () => {
    if (searchQuery.length >= 3) {
      try {
        const encodedFilters = Base64.encodeURL(JSON.stringify({ query: searchQuery }));
        const response = await fetch(`${API_BASE_URL}/stock-search/?filters=${encodedFilters}`);
        const data = await response.json();
        setSearchResults(data);
      } catch (error) {
        console.error('Error fetching search results:', error);
      }
    } else {
      setSearchResults([]);
    }
  }, [searchQuery]);

  useEffect(() => {
    const debounceTimeout = setTimeout(fetchSearchResults, 300);
    return () => clearTimeout(debounceTimeout);
  }, [searchQuery, fetchSearchResults]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.ctrlKey && event.shiftKey && event.key === 'O') {
        event.preventDefault();
        setIsTradePopupOpen(true);
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, []);

  return (
    <div className="toolbar">
      <div className="toolbar-content">
        {/* Left section: Home icon, search bar */}
        <div className="toolbar-left">
          <div className="home-icon-wrapper">
            <Link to="/" aria-label="Home">
              <HomeIcon label="Home icon" primaryColor="#FFFFFF" size="large" />
            </Link>
          </div>

          <div className="search-bar-wrapper" style={{ marginRight: '10px' }}>
            <TextField
              placeholder="Search"
              elemAfterInput={<SearchIcon label="Search icon" />}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            {searchResults.length > 0 && (
              <div className="search-results-dropdown">
                <ul>
                  {searchResults.map((result) => (
                    <li key={result.symbol} onClick={() => handleSearchSelect(result.symbol)}>
                      {result.symbol} - {result.name}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>

          {/* Contact Us Button */}
          <div className="terminal-button-wrapper" >
            <a href={contactUs} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
              <Button appearance="primary">Contact Us</Button>
            </a>
          </div>

          {/* Direct Terminal Button */}
          <div className="terminal-button-wrapper" style={{ display: 'flex', justifyContent: 'flex-end', marginLeft: '10px' }}>
            <Link to="/terminal/brokeragecalls">
              <Button appearance="primary">Terminal</Button>
            </Link>
          </div>
        </div>

        {/* Right section: Buttons */}
        <div className="toolbar-right">
          {isTerminalPage ? (
            <>
              <div className="button-wrapper">
                <Link to="/terminal/commodity">
                  <Button appearance="primary">Commodity</Button>
                </Link>
              </div>
            </>
          ) : (
            currentSymbol && (
              <>
                {['Company Info', 'Fundamentals', 'Analysis'].map((btn) => (
                  <div className="button-wrapper" key={btn}>
                    <Link to={`/${btn.toLowerCase().replace(' ', '-')}/${currentSymbol}`}>
                      <Button appearance="primary">{btn}</Button>
                    </Link>
                  </div>
                ))}
              </>
            )
          )}
        </div>

        {/* Trade Popup */}
        <TradePopup isOpen={isTradePopupOpen} onRequestClose={() => setIsTradePopupOpen(false)} />
      </div>
    </div>
  );
}

export default MyToolbar;