import React from "react";
import { Link } from "react-router-dom";
import "../index.css";
import "../TableSection.css";
import "../homePage.css";

// Constants for services and team members
const contactUs = "https://docs.google.com/forms/d/e/1FAIpQLSdBbmzpHmaZIJS3J1HMZAPFKNRIu9FGM1CQAKos1eSBj1aiyA/viewform?usp=sf_link";

const individualServices = [
    {
        title: "ITR and Tax Planning",
        description: "Simplified Tax Planning",
        color: "#d4e8d4" // Soft Green
    },
    {
        title: "GST Registration and Returns",
        description: "GST registration and return filing services.",
        color: "#ffe3e3" // Light Red/Pink
    },
    {
        title: "Crypto Insights",
        description: "Expert Insights about crypto markets and technology to make better strategic decisions",
        color: "#d8eafc" // Light Blue
    },
    {
        title: "Financial & Educational Counselling",
        description: "With experts across fields, we assist with financial planning and education counseling for your child's future.",
        color: "#ffebeb" // Light Peach
    },
    {
        title: "The Terminal 🚀",
        description: "Glimpse to capital market analytics platform utilized by our proprietary investment desk.",
        color: "#ffecd2",
        link: "/terminal/brokeragecalls"
    }
];

const businessServices = [
    { title: "Trademark Registration", description: "Protect your brand.", color: "#d8eafc" }, // Light Blue
    { title: "Startup Registration", description: "Hassle-free startup registration, government policies", color: "#ffecd2" }, // Light Orange/Yellow
    { title: "ESI/PF Registration and Returns", description: "Efficient employee benefits management", color: "#e7d4ef" }, // Soft Lavender
    { title: "Company Formation", description: "Easy company setup.", color: "#f0e4d8" }, // Beige/Brown
    { title: "CMA Data Preparation", description: "CMA data filing for loan processing.", color: "#d4e8d4" } // Soft Green
];

const teamMembers = [
    { name: "Rohit R Soni", role: "Partner" },
    { name: "Subodh Soni", role: "Partner" },
    { name: "CA Sourabh Soni", role: "Partner" },
    { name: "CA Rajan Verma", role: "Partner" },
    { name: "CA Lisha Verma", role: "Consultant" },
    { name: "CA Vivek Soni", role: "Consultant" },
    { name: "Pulkit Soni", role: "Engineering Consultant (Crypto)" },
    { name: "Anamika Soni", role: "Engineering Consultant" }
];

function HomePage() {
    return (
        <div className="home-page">
            {/* Logo Section */}
            {/* <section className="logo-section" style={{ textAlign: "center", marginBottom: "20px" }}>
                <img src="/path/to/logo.png" alt="Company Logo" style={{ maxWidth: "200px", height: "auto" }} />
            </section> */}

            {/* Hero Banner */}
            <section className="hero-banner">
                <div className="hero-content">
                    <h1>Where Your Goals Meet Our Expertise 🚀</h1>
                    <p>
                        We provide research-backed, data-driven insights to help you make informed financial and business decisions.
                        Our team has 15+ years of expertise in business operations, engineering, and financial consulting.
                    </p>
                    <a
                        href={contactUs}
                        className="know-more-link hero-cta"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                            display: "inline-block",
                            padding: "10px 20px",
                            marginTop: "20px",
                            backgroundColor: "#007BFF",
                            color: "#fff",
                            textDecoration: "none",
                            borderRadius: "5px",
                            fontWeight: "bold",
                            transition: "background-color 0.3s ease"
                        }}
                        onMouseOver={(e) => e.target.style.backgroundColor = "#0056b3"}
                        onMouseOut={(e) => e.target.style.backgroundColor = "#007BFF"}
                    >
                        Request a Call Back
                    </a>
                </div>
            </section>


            {/* Philosophy Section */}
            <section className="philosophy-section">
                <h2>Our Philosophy</h2>
                <p style={{ fontStyle: 'italic' }}>
                    Life can be busy and sometimes overwhelming, especially when it comes to handling family, finance, job, business responsibilities, and planning for the future. Our mission is to support individuals and businesses to make informed, strategic decisions. With our robust network and research-backed insights, we offer unmatched services tailored to your unique needs.
                </p>
            </section>

            {/* Individual Services */}
            <section className="service-highlights">
                <h2>Offerings for Individuals</h2>
                <div className="service-cards">
                    {individualServices.map((service, index) => (
                        <div key={index} className="service-card" style={{ backgroundColor: service.color }}>
                            {service.link ? (
                                <h3>
                                    <Link to={service.link} style={{ color: '#333', textDecoration: 'none' }}>
                                        {service.title}
                                    </Link>
                                </h3>
                            ) : (
                                <h3>{service.title}</h3>
                            )}
                            <p style={{ fontStyle: 'italic' }}>{service.description}</p>
                        </div>
                    ))}
                </div>
            </section>

            {/* Business Services */}
            <section className="service-highlights">
                <h2>Offerings for Businesses</h2>
                <div className="service-cards">
                    {businessServices.map((service, index) => (
                        <div key={index} className="service-card" style={{ backgroundColor: service.color }}>
                            <h3>{service.title}</h3>
                            <p style={{ fontStyle: 'italic' }}>{service.description}</p>
                        </div>
                    ))}
                </div>
            </section>

            {/* Team Section */}
            <section className="team-section">
                <h2>Meet Our Team</h2>
                <div className="team-members">
                    {teamMembers.map((member, index) => (
                        <div key={index} className="team-card">
                            <h3>{member.name}</h3>
                            <p>{member.role}</p>
                        </div>
                    ))}
                </div>
            </section>

            {/* Contact Us */}
            <section className="contact-section">
                <div className="service-card" style={{ backgroundColor: "#f9f9f9" }}>
                    <h3>Get in Touch</h3>
                    <p style={{ fontStyle: 'italic' }}>
                        We’re here to answer your questions and discuss how we can help you achieve your goals.
                    </p>
                    <a
                        href={contactUs}
                        className="know-more-link hero-cta"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                            display: "inline-block",
                            padding: "10px 20px",
                            marginTop: "20px",
                            backgroundColor: "#007BFF",
                            color: "#fff",
                            textDecoration: "none",
                            borderRadius: "5px",
                            fontWeight: "bold",
                            transition: "background-color 0.3s ease"
                        }}
                        onMouseOver={(e) => e.target.style.backgroundColor = "#0056b3"}
                        onMouseOut={(e) => e.target.style.backgroundColor = "#007BFF"}
                    >
                        Request a Call Back
                    </a>
                </div>
            </section>

            {/* Footer */}
            <footer className="footer">
                <div className="footer-content">
                    <div className="footer-section company-info">
                        <h3>Our Company</h3>
                        <p>Address: Jaipur, India</p>
                        <p>Email: kuberanalytics@gmail.com</p>
                        <p>Phone: +91 9739599911</p>
                    </div>
                </div>
                <div className="footer-bottom">
                    <p>&copy; 2024 Kuber Analytics. All rights reserved.</p>
                </div>
            </footer>
        </div>
    );
}

export default HomePage;