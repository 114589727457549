import React, { useState, useEffect } from "react";
import { Base64 } from 'js-base64';
import { API_BASE_URL } from "../App";
import "../index.css";
import "../TableSection.css";

// sequence of indicator codes to be displayed in the table
const indicator_codes_sequence = ['CFO_A', 'CFI_A', 'CFA_A', 'NetIncDecInCashAndCashEquivalents_A', 'CashAndCashEquivalentBeginOfYear_A', 'CashAndCashEquivalentEndOfYear_A', 'WCTO_A']

// Function to determine the background color based on "colour" property
const getCellStyle = (colour, header) => {
    let style = {};
    if (header !== "indicator") {
        style.textAlign = "right"; // Align numeric data to the right
    }

    if (colour === "positive") {
        return { ...style, backgroundColor: "green", color: "white" };  // Green background with white text
    } else if (colour === "negative") {
        return { ...style, backgroundColor: "red", color: "white" };  // Red background with white text
    }
    return style;  // Default style
};

function CashflowStatement({ symbol, type = "cash_flow", period = "annual" }) {
    const [tableData, setTableData] = useState([]);
    const [headers, setHeaders] = useState([]);

    const apiEndpoint = React.useMemo(() => {
        if (!symbol) {
            console.error("Symbol is a mandatory parameter");
            return null;
        }

        const filters = {
            symbol: symbol, // Symbol is mandatory, so no need to check
            type: type,
            period: period
        };

        // Convert filters to JSON and url-safe encode in base64
        const encodedFilters = Base64.encodeURL(JSON.stringify(filters));

        return `${API_BASE_URL}/financial-data/?filters=${encodedFilters}`;
    }, [symbol, type, period]);

    useEffect(() => {
        const fetchData = async () => {
            if (!apiEndpoint) return; // Exit early if the API endpoint is not valid

            try {
                const response = await fetch(apiEndpoint);
                const data = await response.json();

                // Extract headers and data
                const extractedData = data[0]?.fundamentals?.cash_flow?.annual?.consolidated?.data || [];
                const extractedHeaders = data[0]?.fundamentals?.cash_flow?.annual?.consolidated?.headers || [];

                // Filter and sort the data based on indicator_codes_sequence
                const filteredData = indicator_codes_sequence.map(indicatorCode =>
                    extractedData.find(row => row.indicator_code === indicatorCode)
                ).filter(Boolean); // Remove any null or undefined values

                setTableData(filteredData);
                setHeaders(extractedHeaders);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, [apiEndpoint]);

    return (
        <div className="table-section table-section-nowrap">
            <div className="fixed-heading">
                <h2>Cashflow Statement</h2>
            </div>
            <div className="table-container">
                <table>
                    <thead>
                        <tr>
                            {headers.map((header, index) => (
                                <th key={index}>{header}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {tableData.length > 0 ? (
                            tableData.map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                    {headers.map((header, colIndex) => (
                                        <td key={colIndex} style={getCellStyle(row[header]?.colour, header)}>
                                            {row[header]?.value ?? row[header]}
                                        </td>
                                    ))}
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={headers.length}>No Data</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default CashflowStatement;