import React, { useState, useEffect } from "react";
import { Base64 } from 'js-base64';
import { formatDateTime, API_BASE_URL } from "../App";
import "../index.css";
import "../TableSection.css";

// a function to return display fields for the table 
const displayFieldsFunc = (currency = null) => {
    if (currency !== null && currency === "USD") {
        return ["symbol", "bid", "ask", "low", "high", "change", "change_percentage", "date"];
    } else {
        return ["symbol", "close", "open", "low", "high", "change", "change_percentage", "date"];
    }
}

const getCellStyle = (field) => {
    return {
        whiteSpace: 'nowrap',
        textAlign: ["symbol", "change_percentage", "date"].includes(field) ? 'left' : 'right'
    };
};

const getCellClass = (field, value) => {
    if (field === "change") {
        if (value > 0) return 'highlight-green';
        if (value < 0) return 'highlight-red';
    }
    return '';
};

function CommodityMetadata({ currency = null }) {
    const [tableData, setTableData] = useState([]);
    const displayFields = displayFieldsFunc(currency);

    // API Endpoint construction with Base64 encoding
    const getApiEndpoint = () => {
        const filters = currency ? { currency } : {};
        const encodedFilters = Base64.encodeURL(JSON.stringify(filters)); // Base64 encoding the filters
        return `${API_BASE_URL}/commodity-metadata/?filters=${encodedFilters}`;
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(getApiEndpoint());
                const data = await response.json();
                setTableData(data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
        const intervalId = setInterval(fetchData, 300000); // Fetch data every 5 minutes

        return () => clearInterval(intervalId); // Cleanup interval on unmount
    }, [currency]);

    return (
        <div className="table-section">
            <div className="fixed-heading">
                <h2>Metadata</h2>
            </div>
            <div className="table-container">
                <table>
                    <thead>
                        <tr>
                            {displayFields.map((field, index) => (
                                <th key={index}>{field.toUpperCase()}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {tableData.length > 0 ? (
                            tableData.map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                    {displayFields.map((field, colIndex) => (
                                        <td
                                            key={colIndex}
                                            style={getCellStyle(field)}
                                            className={getCellClass(field, row[field])}
                                        >
                                            {["expiry_date", "updated_at", "date"].includes(field) ? formatDateTime(row[field]) : row[field]}
                                        </td>
                                    ))}
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={displayFields.length}>No Data</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default CommodityMetadata;