import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Base64 } from 'js-base64';
import { useParams } from 'react-router-dom';
import { formatDateTime, API_BASE_URL } from "../../App";
import "../../index.css";
import "../../TableSection.css";

function InvestmentSummaryTable() {
    const { symbol, strategy } = useParams();  // Extract symbol and strategy from route params
    const [tableData, setTableData] = useState([]);
    const [loading, setLoading] = useState(true);

    // Display fields for investment summary records
    const displayFields = useMemo(() => ["date", "price_at_purchase", "average_purchase_price", "sub_category", "action"], []);

    // Construct the API endpoint with encoded filter parameters
    const apiEndpoint = useMemo(() => {
        const filters = {
            symbol: symbol,  // Include symbol if provided
            strategy: strategy,  // Include strategy if provided
        };

        // Remove undefined keys from the filters object
        Object.keys(filters).forEach(key => filters[key] === undefined && delete filters[key]);

        if (Object.keys(filters).length > 0) {
            const encodedFilters = Base64.encodeURL(JSON.stringify(filters));
            return `${API_BASE_URL}/trade-strategies/?filters=${encodedFilters}`;
        }
        return `${API_BASE_URL}/trade-strategies/`;
    }, [symbol, strategy]);

    const fetchData = useCallback(async () => {
        setLoading(true);
        try {
            const response = await fetch(apiEndpoint);
            const data = await response.json();

            // Sort the records in descending order based on the date
            const updatedData = data.map((record) => ({
                ...record,
                investment_summary_records: record.result.investment_summary_records
                    .map((summaryRecord) => ({
                        ...summaryRecord,
                        date: summaryRecord.date ? new Date(summaryRecord.date) : null  // Convert string to Date object
                    }))
                    .sort((a, b) => b.date - a.date)  // Sort by date in descending order
            }));

            setTableData(updatedData);  // Set the data after sorting
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    }, [apiEndpoint]);

    useEffect(() => {
        if (!apiEndpoint) return;

        fetchData();
        const intervalId = setInterval(fetchData, 300000);  // Poll every 5 minutes
        return () => clearInterval(intervalId);  // Clean up interval on component unmount
    }, [apiEndpoint, fetchData]);

    if (loading) {
        return <div>Loading...</div>;  // Optional: Add a loading spinner
    }

    return (
        <div className="table-section">
            <div className="fixed-heading">
                <h2>Investment Summary</h2>
            </div>
            <div className="table-container">
                <table>
                    <thead>
                        <tr>
                            {displayFields.map((field, index) => (
                                <th key={index}>{field.toUpperCase()}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {tableData.length > 0 ? (
                            tableData.map((row, rowIndex) => (
                                row.investment_summary_records.map((record, recordIndex) => (
                                    <tr key={`${rowIndex}-${recordIndex}`}>
                                        {displayFields.map((field, colIndex) => (
                                            <td key={colIndex}>
                                                {field === "date" && record.date ? (
                                                    formatDateTime(record.date, 'dd MMM yyyy')  // Format date if valid
                                                ) : (
                                                    record[field] || "N/A"  // Fallback for empty fields
                                                )}
                                            </td>
                                        ))}
                                    </tr>
                                ))
                            ))
                        ) : (
                            <tr>
                                <td colSpan={displayFields.length}>No Data.</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default InvestmentSummaryTable;