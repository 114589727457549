import React, { useState, useEffect, useMemo } from "react";
import { API_BASE_URL } from "../App";
import "../index.css";
import "../TableSection.css";

function AdvisorStats() {
    const [tableData, setTableData] = useState([]);

    const displayFields = useMemo(() => [
        "advisor", "total_pnl", "total_investment", "return_percentage", "average_holding_days", "positive_calls", "negative_calls", "total_calls"
    ], []);

    const apiEndpoint = `${API_BASE_URL}/advisor-pnl-report/`;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(apiEndpoint);
                let data = await response.json();
                if (data.length > 0) {
                    data = data.filter(row => Math.abs(row['total_pnl']) > 10);
                }

                setTableData(data); // Update state with fetched data
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, [apiEndpoint]);

    return (
        <div className="table-section">
            <div className="fixed-heading">
                <h2>Advisor Stats</h2>
            </div>
            <div className="table-container">
                <table style={{ whiteSpace: 'nowrap' }}>
                    <thead >
                        <tr>
                            {displayFields.map((field, index) => (
                                <th key={index}>{field.replace(/_/g, ' ').toUpperCase()}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {tableData.length > 0 ? (
                            tableData.map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                    {displayFields.map((field, colIndex) => (
                                        <td key={colIndex}>{row[field]}</td>
                                    ))}
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={displayFields.length}>No Data.</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default AdvisorStats;