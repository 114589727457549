import React, { useState, useEffect, useMemo } from "react";
import { Base64 } from 'js-base64';
import { API_BASE_URL } from "../App";
import "../index.css";
import "../TableSection.css";

// sequence of indicator codes to be displayed in the table
const indicator_codes_sequence = ['BVPerShareInclRevalReserve_A', 'ROE_A', 'ROCE_A', 'DEBT_CE_A', 'ASETTO_A', 'CRATIO_A', 'NP_A_GROWTH']

// Function to determine the background color based on "colour" property
const getCellStyle = (colour, header) => {
    let style = {};
    if (header !== "indicator") {
        style.textAlign = "right"; // Align numeric data to the right
    }

    if (colour === "positive") {
        return { ...style, backgroundColor: "green", color: "white" };  // Green background with white text
    } else if (colour === "negative") {
        return { ...style, backgroundColor: "red", color: "white" };  // Red background with white text
    }
    return style;  // Default style
};

function FinancialRatiosStatement({ symbol, type = "financial_ratios", period = "annual" }) {
    const [tableData, setTableData] = useState([]);
    const [headers, setHeaders] = useState([]);

    // Create API Endpoint
    const apiEndpoint = useMemo(() => {
        if (!symbol) {
            console.error("Symbol is a mandatory parameter");
            return null;
        }

        const filters = {
            symbol: symbol, // Symbol is mandatory
            type: type,
            period: period
        };

        // Convert filters to JSON and encode in base64
        const encodedFilters = Base64.encodeURL(JSON.stringify(filters));
        return `${API_BASE_URL}/financial-data/?filters=${encodedFilters}`;
    }, [symbol, type, period]);

    useEffect(() => {
        let isMounted = true; // To prevent state updates if the component unmounts

        const fetchData = async () => {
            if (!apiEndpoint) return; // Exit early if the API endpoint is not valid

            try {
                const response = await fetch(apiEndpoint);
                const data = await response.json();

                if (isMounted) {  // Only set state if still mounted
                    // Extract headers and data
                    const extractedData = data[0]?.fundamentals?.financial_ratios?.annual?.consolidated?.data || [];
                    const extractedHeaders = data[0]?.fundamentals?.financial_ratios?.annual?.consolidated?.headers || [];

                    // Filter and sort the data based on indicator_codes_sequence
                    const filteredData = indicator_codes_sequence.map(indicatorCode =>
                        extractedData.find(row => row.indicator_code === indicatorCode)
                    ).filter(Boolean); // Remove any null or undefined values


                    setTableData(filteredData);
                    setHeaders(extractedHeaders);
                }
            } catch (error) {
                if (isMounted) {
                    console.error("Error fetching data:", error);
                }
            }
        };

        fetchData();

        return () => {
            isMounted = false; // Clean up to prevent state updates on unmounted component
        };
    }, [apiEndpoint]);

    return (
        <div className="table-section table-section-nowrap">
            <div className="fixed-heading">
                <h2>Ratios</h2>
            </div>
            <div className="table-container">
                <table>
                    <thead>
                        <tr>
                            {headers.map((header, index) => (
                                <th key={index}>{header}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {tableData.length > 0 ? (
                            tableData.map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                    {/* First column for the indicator name */}
                                    <td>{row.indicator}</td>

                                    {/* Remaining columns for the financial data */}
                                    {headers.slice(1).map((header, colIndex) => (
                                        <td
                                            key={colIndex}
                                            style={getCellStyle(row[header]?.colour, header)}  // Apply color based on the colour code
                                        >
                                            {row[header]?.value !== undefined ? row[header]?.value : "N/A"}
                                        </td>
                                    ))}
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={headers.length}>No Data</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default FinancialRatiosStatement;