import React, { useState, useEffect, useMemo, useCallback } from "react";
import Plot from 'react-plotly.js';
import { Base64 } from 'js-base64';
import { API_BASE_URL } from "../App";
import '../css/ChartSection.css';
// import { size } from "@atlaskit/icon";

// Function to calculate EMA
function calculateEMA(prices, period) {
    const k = 2 / (period + 1);  // Weighting multiplier
    let emaArray = [];

    // Start with the simple moving average (SMA) for the first period
    let sum = 0;
    for (let i = 0; i < period; i++) {
        sum += prices[i];
    }
    const sma = sum / period;
    emaArray[period - 1] = sma;

    // Now calculate the EMA for the rest of the prices
    for (let i = period; i < prices.length; i++) {
        const ema = (prices[i] - emaArray[i - 1]) * k + emaArray[i - 1];
        emaArray.push(ema);
    }

    // The first (period - 1) entries will be `null` because we don't have enough data for those
    for (let i = 0; i < period - 1; i++) {
        emaArray.unshift(null);
    }

    return emaArray;
}

function StockChart({ symbol }) {
    const [chartData, setChartData] = useState([]);
    const [loading, setLoading] = useState(true);

    // Memoized API endpoint
    const finalApiEndpoint = useMemo(() => {
        if (!symbol) return null;
        const filters = { symbol };
        const encodedFilters = Base64.encodeURL(JSON.stringify(filters));
        return `${API_BASE_URL}/historical-data/?filters=${encodedFilters}`;
    }, [symbol]);

    // Fetch data function
    const fetchData = useCallback(async () => {
        setLoading(true);
        try {
            const response = await fetch(finalApiEndpoint);
            const data = await response.json();
            setChartData(data);
        } catch (error) {
            console.error("Error fetching chart data:", error);
        } finally {
            setLoading(false);
        }
    }, [finalApiEndpoint]);

    // Effect to fetch data and poll every 5 minutes
    useEffect(() => {
        let timeoutId;
        const fetchDataWithTimeout = async () => {
            await fetchData();
            timeoutId = setTimeout(fetchDataWithTimeout, 300000);
        };
        fetchDataWithTimeout(); // Initial call to fetch data on mount
        return () => clearTimeout(timeoutId); // Cleanup timeout on unmount
    }, [fetchData]); // Ensure fetchData is memoized with useCallback

    // useEffect(() => {
    //     const fetchData = async () => {
    //         if (!finalApiEndpoint) return;
    //         setLoading(true);
    //         try {
    //             const response = await fetch(finalApiEndpoint);
    //             const data = await response.json();
    //             setChartData(data);
    //         } catch (error) {
    //             console.error("Error fetching chart data:", error);
    //         } finally {
    //             setLoading(false);
    //         }
    //     };

    //     fetchData();
    // }, [finalApiEndpoint]);

    if (loading) return <div>Loading...</div>;

    // Format the chart data for Plotly
    const formattedChartData = chartData.map(item => ({
        ...item,
        timestamp: new Date(item.timestamp),
    }));

    // Extract closing prices to calculate EMA
    const closingPrices = formattedChartData.map(item => item.close);

    // Calculate EMA 5 and EMA 50
    const ema7 = calculateEMA(closingPrices, 7);
    const ema30 = calculateEMA(closingPrices, 30);

    // Get the latest price
    const latestPrice = formattedChartData.length ? formattedChartData[formattedChartData.length - 1].close : null;

    // Candlestick chart data
    const candlestickData = {
        x: formattedChartData.map(item => item.timestamp),
        open: formattedChartData.map(item => item.open),
        high: formattedChartData.map(item => item.high),
        low: formattedChartData.map(item => item.low),
        close: formattedChartData.map(item => item.close),
        type: 'candlestick',
        name: 'Price',
        yaxis: 'y2',  // Assign to right y-axis
        increasing: { line: { color: '#26a69a' } }, // Green for increasing
        decreasing: { line: { color: '#ef5350' } },  // Red for decreasing
        hoverlabel: {
            bgcolor: "white",
            font: { color: "black" }
        },
    };

    // Volume chart data
    const volumeData = {
        x: formattedChartData.map(item => item.timestamp),
        y: formattedChartData.map(item => item.volume),
        type: 'bar',
        name: 'Volume',
        yaxis: 'y',
        marker: { color: 'rgba(31,119,180,0.7)' }, // Customize volume bar color
        opacity: 1,
    };

    // EMA 5 line
    const ema7Data = {
        x: formattedChartData.map(item => item.timestamp),
        y: ema7,
        type: 'scatter',
        mode: 'lines',
        name: 'EMA 7',
        line: {
            color: 'orange',
            width: 1
        },
        // line: { color: 'orange' },
        yaxis: 'y2',  // Overlay on the price y-axis
        hoverinfo: 'none',  // Disable hover for volume
    };

    // EMA 50 line
    const ema30Data = {
        x: formattedChartData.map(item => item.timestamp),
        y: ema30,
        type: 'scatter',
        mode: 'lines',
        name: 'EMA 30',
        line: {
            color: 'sky',
            width: 1
        },
        yaxis: 'y2',  // Overlay on the price y-axis
        hoverinfo: 'none',  // Disable hover for volume
    };

    // Dividend markers data (Price on y2)
    const dividendDates = formattedChartData
        .filter(item => item.dividend)
        .map(item => ({
            x: new Date(item.timestamp),
            y: item.close,
            text: `Dividend: ${item.dividend}`,
        }));

    const dividendMarkers = {
        x: dividendDates.map(item => item.x),
        y: dividendDates.map(item => item.y),
        mode: 'markers',
        type: 'scatter',
        name: 'Dividend',
        yaxis: 'y2',  // Assign to right y-axis
        marker: { color: 'orange', size: 10, symbol: 'diamond' },  // Customize marker
        text: dividendDates.map(item => item.text),
        hoverinfo: 'text'
    };

    // Layout configuration with a dynamic range and line for the latest price
    const layout = {
        title: `${symbol} Historical Price and Volume`,
        xaxis: {
            type: 'date',
            tickformat: "%d %b %y",
            rangeslider: { visible: false },
            showspikes: true,  // Show spike line on y-axis
            spikemode: 'across',
            spikethickness: 1,
            spikecolor: 'grey'
        },

        yaxis: {
            title: 'Volume',
            fixedrange: true,
            side: 'left',
            showgrid: true,  // Ensure grid is visible between volume and price
            domain: [0, 0.2],  // Reserve the bottom 20% for Volume
            autorange: true,   // Enable dynamic range for the price axis
        },

        yaxis2: {
            title: 'Price',
            side: 'right',
            showgrid: true,  // Ensure price grid is visible
            domain: [0.2, 1],  // Reserve the top 80% for Price
            autorange: true,   // Enable dynamic range for the price axis
            showspikes: true,  // Show spike line on y-axis
            spikemode: 'across',
            spikethickness: 1,
            spikecolor: 'grey'
        },
        shapes: latestPrice ? [
            {
                type: 'line',
                xref: 'paper',
                x0: 0,
                x1: 1,
                yref: 'y2',
                y0: latestPrice,
                y1: latestPrice,
                line: {
                    color: 'grey',
                    width: 1,
                    dash: 'dashdot'
                }
            }
        ] : [],
        annotations: latestPrice ? [
            {
                xref: 'paper',
                x: .9,  // Position near the y-axis (close to the right edge)
                yref: 'y2',  // Refer to the y-axis for price
                y: latestPrice,
                xanchor: 'left',
                yanchor: 'middle',
                text: `${latestPrice.toFixed(2)}`,  // Display the latest price value
                showarrow: false,
                font: {
                    color: 'white',
                    size: 10,
                },
                align: 'right',
                bgcolor: 'black',
            }
        ] : [],
        showlegend: true,
        legend: {
            // x: 0,
            // y: -0.2,
            xanchor: 'top',
            yanchor: 'top',
            orientation: 'h',
            font: {
                size: 10, // Smaller font size
                color: '#333', // Optional: customize text color
                family: 'Arial, sans-serif', // Optional: customize font family
            },
        },
        margin: { t: 40, r: 50, l: 50, b: 20 },
    };

    return (
        <div className="chart-wrapper">
            <Plot
                data={[candlestickData, volumeData, dividendMarkers, ema7Data, ema30Data]}  // Include both candlestick, volume, and EMA data
                layout={layout}
                config={{ responsive: true }}
                style={{ width: '100%', height: '100%' }}
            />
        </div>
    );
}

export default StockChart;