import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Base64 } from 'js-base64';
import { useParams } from 'react-router-dom';
import { formatDateTime, API_BASE_URL } from "../../App";
import "../../index.css";
import "../../TableSection.css";

function InvestmentStats() {
    const { symbol, strategy } = useParams();  // Extract symbol and strategy from route params
    const [tableData, setTableData] = useState(null); // Set initial state to null
    const [loading, setLoading] = useState(true);

    // Construct the API endpoint with encoded filter parameters
    const apiEndpoint = useMemo(() => {
        const filters = {
            symbol: symbol || undefined,  // Include symbol if provided
            strategy: strategy
        };

        // Remove undefined keys from the filters object
        Object.keys(filters).forEach(key => filters[key] === undefined && delete filters[key]);

        if (Object.keys(filters).length > 0) {
            const encodedFilters = Base64.encodeURL(JSON.stringify(filters));
            return `${API_BASE_URL}/trade-strategies/?filters=${encodedFilters}`;
        }
        return `${API_BASE_URL}/trade-strategies/`;
    }, [symbol, strategy]);

    const fetchData = useCallback(async () => {
        setLoading(true);
        try {
            const response = await fetch(apiEndpoint);
            const data = await response.json();

            if (data && data.length > 0) {
                setTableData(data[0]);  // Assuming we're dealing with the first record only
            } else {
                setTableData(null);  // If no valid data, set to null
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            setTableData(null);  // Handle error case
        } finally {
            setLoading(false);
        }
    }, [apiEndpoint]);

    useEffect(() => {
        if (!apiEndpoint) return;

        fetchData();
        const intervalId = setInterval(fetchData, 300000);  // Poll every 5 minutes
        return () => clearInterval(intervalId);  // Clean up interval on component unmount
    }, [apiEndpoint, fetchData]);

    if (loading) {
        return <div>Loading...</div>;  // Optional: Add a loading spinner
    }

    if (!tableData) {
        return <div>No data available</div>;  // Handle case when there is no data
    }

    // Helper function to create table rows dynamically, excluding nested objects and certain keys
    const createRows = (data, type) => {
        return Object.keys(data)
            .filter((key) => key !== 'chart' && key !== 'investment_summary_records')  // Exclude specific keys
            .map((key) => {
                const value = data[key];

                // Check if value is an object or array, and skip it
                if (typeof value === 'object' && value !== null) {
                    return null; // Skip nested objects or arrays
                }

                return (
                    <tr key={`${type}-${key}`}>
                        <td>{type.toUpperCase()}</td>  {/* Type in uppercase */}
                        <td>{key.replace(/_/g, " ").toUpperCase()}</td>
                        <td>{key.includes("date") ? formatDateTime(value) : value}</td>
                    </tr>
                );
            });
    };

    const { conditions = {}, result = {} } = tableData || {};  // Default to empty objects

    return (
        <div className="table-section">
            <div className="fixed-heading">
                <h2>Investment Stats</h2>
            </div>
            <div className="table-container">
                <table>
                    <thead>
                        <tr>
                            <th>TYPE</th>
                            <th>NAME</th>
                            <th>VALUE</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(conditions).length > 0 ? createRows(conditions, "conditions") : null}
                        {Object.keys(result).length > 0 ? createRows(result, "result") : null}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default InvestmentStats;