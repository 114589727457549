import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Base64 } from 'js-base64';
import { API_BASE_URL } from "../App";
import "../index.css";
import "../TableSection.css";

// sequence of indicator codes to be displayed in the table
const indicator_codes_sequence = [
    'TotalShareCapital_A',
    'TotalReservesAndSurplus_A',
    'TotalShareHoldersFunds_A',
    'LiabilityMinorityInterest_A',
    'HybridDebtOtherSecurities_A',
    'LongTermBorrowings_A',
    'DeferredTaxLiabilities_A',
    'OtherLongTermLiabilities_A',
    'LongTermProvisions_A',
    'TotalNonCurrentLiabilities_A',
    'ShortTermBorrowings_A',
    'TradePayables_A',
    'OtherCurrentLiabilities_A',
    'ShortTermProvisions_A',
    'CL_A',
    'TL_A',
    'FixedAssets_A',
    'NonCurrentInvestments_A',
    'DeferredTaxAssets_A',
    'LongTermLoansAndAdvances_A',
    'OtherNonCurrentAssets_A',
    'TotalNonCurrentAssets_A',
    'CurrentInvestments_A',
    'Inventories_A',
    'TradeReceivables_A',
    'ShortTermLoansAndAdvances_A',
    'OtherCurrentAssets_A',
    'CashAndCashEquivalents_A',
    'CA_A',
    'TA_A',
    'ContingentLiabilities_A',
]

// Function to determine the background color based on "colour" property
const getCellStyle = (colour, header) => {
    let style = {};
    if (header !== "indicator") {
        style.textAlign = "right"; // Align numeric data to the right
    }

    if (colour === "positive") {
        return { ...style, backgroundColor: "green", color: "white" };  // Green background with white text
    } else if (colour === "negative") {
        return { ...style, backgroundColor: "red", color: "white" };  // Red background with white text
    }
    return style;  // Default style
};

function BalanceSheetStatement({ symbol, type = "balance_sheet", period = "annual" }) {
    const [tableData, setTableData] = useState([]);
    const [headers, setHeaders] = useState([]);
    const [loading, setLoading] = useState(true);

    const apiEndpoint = useMemo(() => {
        if (!symbol) {
            console.error("Symbol is a mandatory parameter");
            return null;
        }

        const filters = {
            symbol: symbol, // Symbol is mandatory
            type: type,
            period: period
        };

        // Convert filters to JSON and encode in base64
        const encodedFilters = Base64.encodeURL(JSON.stringify(filters));

        return `${API_BASE_URL}/financial-data/?filters=${encodedFilters}`;
    }, [symbol, type, period]);

    const fetchData = useCallback(async () => {
        if (!apiEndpoint) return; // Exit early if the API endpoint is not valid

        setLoading(true);
        try {
            const response = await fetch(apiEndpoint);
            const data = await response.json();

            // Extract headers and data
            const extractedData = data[0]?.fundamentals?.balance_sheet?.annual?.consolidated?.data || [];
            const extractedHeaders = data[0]?.fundamentals?.balance_sheet?.annual?.consolidated?.headers || [];

            // Filter and sort the data based on indicator_codes_sequence
            const filteredData = indicator_codes_sequence.map(indicatorCode =>
                extractedData.find(row => row.indicator_code === indicatorCode)
            ).filter(Boolean); // Remove any null or undefined values

            setTableData(filteredData);
            setHeaders(extractedHeaders);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    }, [apiEndpoint]);

    useEffect(() => {
        let isMounted = true; // To prevent state updates if the component unmounts

        if (isMounted) {
            fetchData();
        }

        return () => {
            isMounted = false; // Clean up to prevent state updates on unmounted component
        };
    }, [fetchData]);

    if (loading) {
        return <div>Loading...</div>; // Replace with a loading spinner or skeleton screen
    }

    return (
        <div className="table-section table-section-nowrap">
            <div className="fixed-heading">
                <h2>Balance Sheet</h2>
            </div>
            <div className="table-container">
                <table>
                    <thead>
                        <tr>
                            {headers.map((header, index) => (
                                <th key={index}>{header}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {tableData.length > 0 ? (
                            tableData.map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                    {headers.map((header, colIndex) => (
                                        <td key={colIndex} style={getCellStyle(row[header]?.colour, header)}>
                                            {row[header]?.value ?? row[header]}
                                        </td>
                                    ))}
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={headers.length}>No Data</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default BalanceSheetStatement;